<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Tables</div>
                    <div class="card-body">
                        <table class="table" style="max-width: 700px">
                            <thead>
                            <tr>
                                <th style="width:5%;"><input type="checkbox"></th>
                                <th style="width:10%;">Text</th>
                                <th class="text-center">Text</th>
                                <th class="text-right" style="width:15%;">Text</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Table / Mixed</div>
                    <div class="card-body">
                        <table class="table table-striped" style="max-width: 700px">
                            <thead>
                            <tr>
                                <th style="width:5%;"><input type="checkbox"></th>
                                <th style="width:10%;">Text</th>
                                <th class="text-center">Text</th>
                                <th class="text-right" style="width:15%;">Text</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Table / Striped</div>
                    <div class="card-body">
                        <table class="table table-striped table-borderless" style="max-width: 700px">
                            <thead>
                            <tr>
                                <th style="width:5%;"><input type="checkbox"></th>
                                <th style="width:10%;">Text</th>
                                <th class="text-center">Text</th>
                                <th class="text-right" style="width:15%;">Text</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Table / Contextual Classes</div>
                    <div class="card-body">
                        <table class="table table-striped" style="max-width: 700px">
                            <thead>
                            <tr>
                                <th style="width:5%;"><input type="checkbox"></th>
                                <th style="width:10%;">Text</th>
                                <th class="text-center">Text</th>
                                <th class="text-right" style="width:15%;">Text</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td class="table-danger text-danger">Text</td>
                                <td class="table-danger text-danger text-center">Text</td>
                                <td class="table-danger text-danger text-right">
                                    <fa class="mr-2" icon="exclamation-circle" style="opacity: 0.3"></fa>
                                    <fa class="mr-2" icon="exclamation-circle" style="opacity: 0.3"></fa>
                                    <fa icon="exclamation-circle" style="opacity: 0.3"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td class="table-success text-success">Text</td>
                                <td class="text-center table-success text-success">Text</td>
                                <td class="text-right table-success text-success">
                                    <fa class="mr-2" icon="exclamation-circle" style="opacity: 0.3"></fa>
                                    <fa class="mr-2" icon="exclamation-circle" style="opacity: 0.3"></fa>
                                    <fa icon="exclamation-circle" style="opacity: 0.3"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Text</td>
                                <td class="text-center">Text</td>
                                <td class="text-right">
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted mr-2" icon="exclamation-circle"></fa>
                                    <fa class="text-muted" icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            <tr>
                                <td><input checked type="checkbox"></td>
                                <td class="table-warning text-warning">Text</td>
                                <td class="text-center table-warning text-warning">Text</td>
                                <td class="text-right table-warning text-warning">
                                    <fa class="mr-2" icon="exclamation-circle"></fa>
                                    <fa class="mr-2" icon="exclamation-circle"></fa>
                                    <fa icon="exclamation-circle"></fa>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Table',
}
</script>
